import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v(" Password Reset "),_c('p'),_c(VAlert,{attrs:{"type":_vm.alertType},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(VForm,[(_vm.waiting)?_c(VProgressLinear,{attrs:{"indeterminate":true}}):_vm._e(),_c('ValidationProvider',{attrs:{"mode":"aggressive","rules":"requiredPassword|minPassword:6|password:confirmation","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"type":"password","name":"password","error-messages":errors[0],"label":"New Password","solo":"","disabled":_vm.waiting},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"confirmation","mode":"aggressive","rules":"requiredPassword|minPassword:6","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"type":"password","name":"password_confirmation","error-messages":errors[0],"label":"Confirm the password","solo":"","disabled":_vm.waiting},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)}),_c(VBtn,{staticClass:"mb-3 primary",attrs:{"type":"submit","block":"","large":"","disabled":invalid || _vm.waiting},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Submit ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }